.navbar-module--brand {
    padding-right: 1rem;
    /* height: 3rem; */
}

.navbar-module--js {
    background-color: #ff3860;
    border-radius: 4px;
    /* height: 3rem; */
    transition-duration: 2.04s;
    transition-property: background-color;
    width: 3rem;
}

.navbar-module--js p {
    color: #000;
    font-size: 24px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-module--title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navbar-module--name {
    font-weight: 500;
}

.navbar-module--tagline {
    font-style: normal;
    opacity: .3;

}

.available-module--available {
    align-items: center;
    background: var(--background);
    border-radius: 9999px;
    color: var(--current-text-light);
    display: flex;
    font-size: 1em;
    justify-content: center;
    line-height: 1.5;
    padding: 0.25em 0.75em;
    transition-duration: 294ms;
    transition-property: background-color, color;
    transition-timing-function: ease-out;
    white-space: nowrap;
    position: relative;
    font-size: 14px;
}

.available-module--available.available-module--is-active,
.available-module--available:hover {
    background-color: rgba(var(--success-rgb), .1);
    color: var(--success);
    text-decoration: none;

}

.available-module--available .available-module--dot {
    margin: 0.5em 0;
}

.available-module--dot {
    --color: var(--success);
    background-color: var(--color);
    border-radius: 9999px;
    height: 0.5em;
    position: relative;
    width: 0.5em;
}

.available-module--available span:last-child {
    margin-left: 0.5em;
    padding-bottom: 2px;
}

.available-module--available em {
    font-style: normal;
    opacity: .7;
}

.available-module--available strong {
    color: inherit;
    font-weight: 500;
}

.un {
    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(#ff3860, #ff3860);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; /*change after the size immediately*/
  }
  
  .un:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
  }

  .active {
    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(#ff3860, #ff3860);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
  }

 