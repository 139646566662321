.category-card {
    height: 7rem;
    background-color: #fff;
    border-radius: .5rem;
}

.category-card-title {
    font-weight: 400;
    font-size: .7rem;
}

.category-card-total {
    font-weight: 600;
    font-size: 2rem;
}

.cat-img {
    height: 6rem;
    width: 6rem;
}