.button-module--button {
  background-color: #ff3860;
  -webkit-appearance: none;
  appearance: none;
  font-size: 1.2rem;
  text-align: left;

  /* background: none; */
  border: none;
  border-radius: 0.375em;
  color: currentColor;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  letter-spacing: -.04em;
  line-height: 1.125;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  padding: 0.75em 1.5em;
  position: relative;
  transition-duration: 294ms;
  transition-property: background-color, color, box-shadow;
  transition-timing-function: ease-out;
  vertical-align: top;
}

.button-module--button:hover {
  color: #fff;
}
