.notebook-card {
    border-radius: .7rem;
    /* height: 18rem; */
}

.notebook-card img {
    height: 12rem;
    width: 100%;
}

.text {
    position: relative;
    background-image: linear-gradient(to right, #283048, #414345);
    padding: .7rem;
    color: white;
    font-size: 1rem;
    font-family: 'Mansalva', cursive;
    transform: rotate(-5deg);
    text-align: center;
    /* width: 100%; */
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.notebook-heading {
    font-weight: 600;
    font-size: 1.1rem;
}

.post-of-the-day {
    /* height: 35vh; */
    width: 100%;
    font-family: 'Mansalva', cursive;
    /* background-color: #FCFDF6; */
}

.post-of-the-day img {
    height: 100%;
    width: 100%;
}