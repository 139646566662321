body {
  margin: 0;
  font-family: Outfit,BlinkMacSystemFont,-apple-system,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



a {
    color: #000;
    text-decoration: none;
}


a:hover {
    text-decoration: none;
    color: #000;
}

.highlight-sidebar {
  color: #0400ff;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #142664;
}

.glitch{
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 
      0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    animation: glitch 500ms infinite;
    position: relative;
  }
  
  .glitch span{
    position: absolute;
    top: 0;
    bottom:0;
    left: 0;
    right: 0;
  }
  
  .glitch span:first-child{
    animation: glitch 650ms infinite;
    z-index: 1;
    clip-path: polygon(0 50%, 100% 20%, 100% 100%, 0 100%);
    transform: translate(0px, 3px) rotate(-1deg);
    opacity: 0.8;
  }
  
  .glitch span:last-child{
    animation: glitch 450ms infinite;
    z-index: 10;
    clip-path: polygon(70% 10%, 100% 90%, 0% 10%, 100% 0%);
    transform: translate(-3px, 0px) rotate(1deg);
  }
  /*Animation effect for glitch*/
  @keyframes glitch {
    0% {
      text-shadow:
        0.05em 0 0 rgba(255, 0, 0, 0.75),
        -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    14% {
      text-shadow: 
        0.05em 0 0 rgba(255, 0, 0, 0.75),
        -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    }
    15% {
      text-shadow: 
        -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
        0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
        -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    49% {
      text-shadow: 
        -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
        0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
        -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    50% {
      text-shadow: 
        0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
        0.05em 0 0 rgba(0, 255, 0, 0.75),
        0 -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    99% {
      text-shadow: 
        0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
        0.05em 0 0 rgba(0, 255, 0, 0.75), 
        0 -0.05em 0 rgba(0, 0, 255, 0.75);
    }
    100% {
      text-shadow: 
        -0.025em 0 0 rgba(255, 0, 0, 0.75),
        -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
        -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
    }
  }

  .loading-wrapper {
    width: 82vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bar {
    display: block;
    height: 36px;
    width: 10px;
    background: #142664;
    margin-right: 24px;
    animation: scale 1.6s ease-in-out infinite;
    transform-origin: 50% 50%;
    box-shadow: 2px 2px 2px #00aeff;
  }
  
  .bar.one {
    animation-delay: 1s;
  }
  
  .bar.two {
    animation-delay: 1.2s;
  }
  
  .bar.three {
    animation-delay: 1.3s;
  }
  
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .one-heading h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .one-heading h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 5px;
    left: 50%;
    margin-left: -50px;
    background-color: #142664;
  }
  