.most-viewed-posts-heading {
    font-weight: 500;
    font-size: .8rem;
}

.lessons-card  {
    background-color: #fff;
    height: 10rem;
    border-radius: 0.5rem;
}

.lessons-img img {
    max-width: 100%;
    height: 7.5rem;
}

.lessons-text {
    font-size: .7rem;
    font-weight: 600;

}

.fixed-height {
    height: 6rem;
}

/* .lessons-content {
    height: 100%;
} */
