.indv-card {
    background-color: #fff;
    height: 9rem;
    border-radius: 0.5rem;
}

.line {
    border-radius: 10rem;
    width: .2rem;
}

.tags {
  font-size: .4rem;
  font-weight: 700;
  background-color: #dee2e6; 
  padding: 0.3rem;
  border-radius: .3rem;
}

.title-post {
    /* line-height: 1rem; */
    font-weight: 700;
}

.post-timing-text {
    color: #858b90;
    font-size: .6rem;
    font-weight: 600;
}

.post-timing-date {
    font-weight: 400;
}

.bookmarked {
    font-size: .6rem;
    font-weight: 600;
    padding: 0.3rem;
    border-radius: .3rem;
}

.blog-posts-heading {
    font-weight: 500;
    font-size: .8rem;
}

hr {
    margin-top: 2rem;
}